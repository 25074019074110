/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;
$vin-yellow: yellow;

#wrapper { margin: 0 auto; padding: 85px 0; position: relative; width: 980px; text-align: center; font-family: 'Open Sans'; color: #444; }
#wrapper > h1 { opacity: 0; font: 700 36px/40px Montserrat; font-size: 100px; }
#wrapper > h2 { padding: 10px 0 50px; font: italic 300 22px/34px 'Open Sans'; font-size: 34px}
#wrapper > h3 { margin: 0 0 30px; font: 300 16px/24px 'Open Sans'; }
#wrapper > img { margin-bottom: 50px; }
#wrapper > p { margin: 30px 0 30px; line-height: 28px; }

html, body {
  height: 100%;
  margin: 0;
  width:100%;
  padding:0;
  position: absolute;
}

.gradDynamic {
}

.gradDynamic:after, .gradDynamic:before{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  content:"";
  z-index: -1;
}

.gradDynamic:after{
  background:radial-gradient(circle,$vin-red,transparent);
  background-size: 400%;
  animation:colorSpin 30s linear infinite;
}

.gradDynamic:before{
  background-color: $vin-yellow;
}


@keyframes colorSpin{
  25%{background-position: 0 100%}
  50%{background-position: 100% 100%}
  75%{background-position: 100% 0}
  100%{filter:hue-rotate(360deg)}
}
/* Styles */
// npm 


